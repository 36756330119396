<script setup lang="ts">
import { onClickOutside, useElementBounding } from '@vueuse/core'
import { PropType } from 'vue'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { useFloatingUI } from '../../../hooks/useFloatingUI'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { TestIds } from '../../../constants/TestIds'

const props = defineProps({
	entries: { type: Object as PropType<String[]>, required: true },
	selectedEntry: { type: Number, default: -1 },
	forcedText: String,
	disabled: { type: Boolean, default: false },
	light: { type: Boolean, default: false },
	testId: { type: String as PropType<keyof typeof TestIds>, default: false },
})
const { entries, selectedEntry, forcedText, disabled, light } = toRefs(props)
const emit = defineEmits([EmitsEnum.Click])

const currentEntry = computed(() =>
	selectedEntry.value >= 0 && selectedEntry.value < entries.value.length
		? entries.value[selectedEntry.value]
		: useLocale().translate(TranslationKeys.CHOSE_AN_OPTION)
)

const onClick = (val: number) => {
	emit(EmitsEnum.Click, val)
}

const rotate = computed(() => {
	return isFloatingElementHidden.value ? '' : '-rotate-180'
})

const additionalClasses = computed(() => {
	let defaultClasses = ['flex', 'cursor-pointer']

	if (!light.value) {
		defaultClasses.push(
			'border-dark-blue-200',
			'px-4',
			'py-2',
			'border',
			'rounded-md',
			'bg-sky-50',
			'border-sky-200',
			'justify-between'
		)
	} else {
		defaultClasses.push('gap-2')
	}

	if (disabled.value) {
		defaultClasses.push('bg-dark-blue-100', 'border-dark-blue-200', 'px-4', 'py-2', 'border', 'rounded-md')
	}

	return cn(defaultClasses, additionalClasses)
})

const caretName = SpSvg.BasicCarretDown
const iconName = SpSvg.BasicCheck
const iconSize = IconSizes.XS

const referenceRef = ref()
const floatingRef = ref()
const { isFloatingElementHidden, toggleFloatingElement } = useFloatingUI(referenceRef, floatingRef, {
	placement: 'bottom-start',
})
onClickOutside(referenceRef, () => {
	!isFloatingElementHidden.value && toggleFloatingElement()
})
const { width: inputWidth } = useElementBounding(referenceRef)
</script>

<template>
	<div ref="referenceRef" class="relative w-full">
		<div class="flex rounded-md" :class="additionalClasses" @click="toggleFloatingElement">
			<span class="truncate text-sm" :class="disabled ? 'text-dark-blue-300' : ''">
				{{ forcedText || currentEntry }}
			</span>
			<CommonIcon
				:class="` shrink-0 transition-all duration-300 ease-out ${rotate}`"
				:icon-name="caretName"
				:fill="disabled ? 'fill-dark-blue-300' : 'fill-black'"
				:icon-size="iconSize"
			/>
		</div>
		<ClientOnly>
			<Teleport to="#dropdowns-container">
				<div
					ref="floatingRef"
					:class="isFloatingElementHidden && 'hidden'"
					:style="{ width: `${inputWidth}px` }"
					class="absolute flex max-h-48 w-full cursor-pointer flex-col !overflow-y-auto rounded-md bg-white shadow-lg"
					:data-testid="testId && TestIds[testId]"
				>
					<div
						v-for="(entry, index) in entries"
						:key="index"
						@click="onClick(index)"
						class="flex items-center gap-2 p-2 hover:bg-dark-blue-100"
					>
						<CommonIcon
							:fill="index == selectedEntry ? 'fill-black' : 'none'"
							:icon-size="iconSize"
							:icon-name="iconName"
						/>
						<span class="truncate text-sm">{{ entry }}</span>
					</div>
				</div>
			</Teleport>
		</ClientOnly>
	</div>
</template>
